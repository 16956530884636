<template>
  <main id="skip-to-main">
    <div class="top-yallow-bg"></div>

    <div class="top-yellow">
      <div class="w1200">
        <div class="demo-txt-wrap">
          <h1 class="main-head biger">
            Digital
            <span class="head-line-wrap">
              Inclusion.
              <img src="../assets/line-red.svg" class="head-line" alt="" />
            </span>
          </h1>
          <p class="demo-sub">
            Does your web application need to be certified for accessibility?
          </p>
        </div>
      </div>
    </div>
    <div class="demo-wrap">
      <div class="form-wrap">
        <p class="form-top-txt">
          We will test your product's main user flows so that you can understand
          what accessibility is all about.
        </p>
        <form
          @submit.prevent="sendForm"
          v-if="formStatus != 'success'"
          class="form"
        >
          <label for="email" class="label-top">Company Email Address</label>
          <input
            required
            id="email"
            v-model="form.email"
            type="email"
            class="txt-field"
            placeholder="Company Email"
          />
          <div>
            <div>
              <label for="firstName" class="label-top">First Name</label>
              <input
                required
                id="firstName"
                placeholder="Your First Name"
                v-model="form.firstName"
                type="text"
                class="txt-field"
              />
            </div>
            <div>
              <label for="lastName" class="label-top">Last Name</label>
              <input
                id="lastName"
                placeholder="Your Last Name"
                v-model="form.lastName"
                type="text"
                class="txt-field"
              />
            </div>
          </div>
          <label for="company" class="label-top">Company</label>
          <input
            required
            id="company"
            placeholder="Company Name"
            v-model="form.company"
            type="text"
            class="txt-field"
          />
          <label for="accessibility" class="label-top">
            Do you currently implement digital accessibility for your product?
          </label>
          <textarea
            id="accessibility"
            placeholder="Either in an automated process or otherwise"
            v-model="form.accessibility"
            class="txt-field big"
          ></textarea>

          <label for="vpat" class="label-top"
            >Does your company produce quarterly VPAT reports?</label
          >
          <textarea
            id="vpat"
            v-model="form.vpat"
            class="txt-field big"
          ></textarea>
          <div class="form-btn-wrap">
            <input
              :disabled="formStatus === 'sending'"
              class="btn full"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
        <div class="tnx" v-if="formStatus == 'success'">
          <p class="">Thank You!</p>
        </div>
        <div class="error" v-if="formStatus == 'fail'">
          <p>An error occurred while submitting the forum</p>
        </div>
      </div>
      <div class="demo-img-wrap">
        <img src="../assets/person-scene2.svg" alt="accessibility vision" />
      </div>
    </div>
  </main>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    document.title = "SenseIT | Demo";
  },

  data: () => ({
    formStatus: "",
    form: {
      email: "",
      firstName: "",
      lastName: "",
      company: "",
      accessibility: "",
      vpat: "",
    },
  }),
  methods: {
    async sendForm() {
      this.formStatus = "sending";
      try {
        await this.$db.collection("demo").post(this.form);
        this.formStatus = "success";
      } catch (err) {
        this.formStatus = "fail";
      }
    },
  },
  name: "Demo",
};
</script>

<template>
  <main id="skip-to-main">
    <div class="top-yallow-bg"></div>
    <div class="top-yellow">
      <div class="under-hero">
        <h1 class="main-head" v-html="formatToHTML(home.mainHead)">
          <!-- Automated usability testing for digital
          <span class="head-line-wrap">accessibility<img src="../assets/line-red.svg" class="head-line" /></span> -->
        </h1>
        <p class="txt30 space-after-50px subtitle">{{ home.subtitle }}</p>
        <router-link to="/demo" class="btn" title="product demo"
          >REQUEST A DEMO</router-link
        >
      </div>
      <div class="product-demo">
        <div class="top-float-txt-box">
          <div class="float-txt-box first">
            <p>Manage and maintain your progress.</p>
            <img src="../assets/arrow.svg" class="arrow1" alt="arrow" />
          </div>
          <div class="float-txt-box">
            <p>Find and correct issues before they get to production</p>
            <img src="../assets/arrow2.svg" class="arrow2" alt="arrow" />
          </div>
        </div>
        <div class="animation-wrap">
          <SVGator
            alt="SenseIT Demo"
            src="/senseit-light2.svg"
            :paused="svgPlay"
          />

          <button
            @click="svgPlay = !svgPlay"
            :title="!svgPlay ? 'Pause' : 'Play'"
            type="button"
            class="pause-btn"
          >
            {{ svgPlay ? "" : "" }}
          </button>
        </div>
        <div class="float-txt-box bot">
          <img src="../assets/arrow2.svg" class="arrow3" alt="arrow" />
          <p>
            Runs automated accessibility tests on your UI in pre-production.
          </p>
        </div>
      </div>

      <!-- <div class="product-demo static">
        <img src="../assets/static-conv.svg" />
      </div> -->
    </div>
    <main id="skip-to-main">
      <div class="prod-sec">
        <div class="w1200">
          <div class="w730 space-after-75">
            <h3 class="txt50">{{ home.middleHead }}</h3>
            <p
              class="txt28 subtitle"
              v-html="formatToHTML(home.middleSubtitle)"
            ></p>
          </div>
          <div class="flex-me top">
            <div
              v-for="icon in home.icons"
              :key="icon.iconText"
              class="icon-box"
            >
              <div class="icon-wrap">
                <img
                  :src="icon.icon.imageURL"
                  class="icon"
                  :alt="icon.icon.altText"
                />
              </div>
              <p class="txt30 semi">{{ icon.iconText }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="gray-sec">
        <div class="w1200 center">
          <div class="w730">
            <h4 class="txt50">
              {{ home.integrateHead }}
            </h4>
            <!-- <h4 class="txt30">"subtitle/explanation":</h4> -->
            <p
              class="txt28 space-after-75 subtitle"
              v-html="formatToHTML(home.integrateSubtitle)"
            ></p>
          </div>
        </div>
        <div style="text-align: center">
          <div class="w1200">
            <img src="" alt="" />
            <img
              src="../assets/left.svg"
              class="left"
              alt='Accessibility is entered at the first stage of "Development - Integration - Production" process chart'
            />

            <div>
              <p>Integrates with:</p>
              <div
                v-for="logo in home.logos"
                :key="logo.logo.imageURL"
                class="integrated-wrap"
              >
                <img
                  :src="logo.logo.imageURL"
                  class="integrated"
                  :class="{ graylogo: logo.comingSoon }"
                  :alt="logo.logo.altText"
                />
                <p v-if="logo.comingSoon">Coming Soon</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="gray-sec hide">
        <div class="w1200 center">
          <div class="w730">
            <h4 class="txt50 space-after-30">Decrease time to market</h4>
            <p class="txt28 space-after-75">
              Testing for accessibility issues early allows you to meet your
              product release deadlines.
            </p>
          </div>
          <div class="flex-me top">
            <div class="icon-box">
              <div class="icon-wrap">
                <img
                  src="../assets/icon-7.svg"
                  class="icon"
                  alt="Reduce Risk liability"
                />
              </div>
              <p class="txt30 semi">Reduce risk<br />&amp; liability&nbsp;</p>
            </div>
            <div class="icon-box">
              <div class="icon-wrap">
                <img
                  src="../assets/icon4.svg"
                  class="icon"
                  alt="Decrease time to market"
                />
              </div>
              <p class="txt30 semi">Decrease time to market</p>
            </div>
            <div class="icon-box">
              <div class="icon-wrap">
                <img
                  src="../assets/icon2.svg"
                  class="icon"
                  alt="Implement Continuous Accessibility"
                />
              </div>
              <p class="txt30 semi">Continuous integration</p>
            </div>
          </div>
        </div>
      </div>
      <div class="testimonials-wrap hide">
        <div class="scroller">
          <div class="testimonial-box">
            <div class="stars-wrap">
              <img src="../assets/Stars.svg" class="star" alt="star" /><img
                src="../assets/Stars.svg"
                class="star"
                alt="star"
              /><img src="../assets/Stars.svg" class="star" alt="star" /><img
                src="../assets/Stars.svg"
                class="star"
                alt="star"
              /><img src="../assets/Stars.svg" class="star" alt="star" />
            </div>
            <p>Outstanding product! with super smooth integration</p>
          </div>
          <div class="testimonial-box">
            <div class="stars-wrap">
              <img src="../assets/Stars.svg" class="star" alt="star" /><img
                src="../assets/Stars.svg"
                class="star"
                alt="star"
              /><img src="../assets/Stars.svg" class="star" alt="star" /><img
                src="../assets/Stars.svg"
                class="star"
                alt="star"
              /><img src="../assets/Stars.svg" class="star" alt="star" />
            </div>
            <p>Outstanding product! with super smooth integration</p>
          </div>
          <div class="testimonial-box">
            <div class="stars-wrap">
              <img src="../assets/Stars.svg" class="star" alt="star" /><img
                src="../assets/Stars.svg"
                class="star"
                alt="star"
              /><img src="../assets/Stars.svg" class="star" alt="star" /><img
                src="../assets/Stars.svg"
                class="star"
                alt="star"
              /><img src="../assets/Stars.svg" class="star" alt="star" />
            </div>
            <p>Outstanding product! with super smooth integration</p>
          </div>
        </div>
      </div>
      <div class="shift-sec hide">
        <div class="w1000">
          <div class="flex-me">
            <div class="side-img-wrap">
              <img
                src="../assets/tech-person.svg"
                class="phone"
                alt="Accessibility phone"
                height="400"
              />
            </div>
            <div class="side-txt-wrap">
              <h2 class="txt50">Shifting Left for Accessibility</h2>
              <p class="txt28">
                Rather than trying&nbsp;make your product accessible after
                release. Execute critical tests for accessibility
                <b>EARLY</b> in the development cycle. Reduce your cost, and
                shorten time to market.
              </p>

              <div>
                <p>Integrated with:</p>
                <img
                  src="../assets/Jenkins.png"
                  class="integrated"
                  alt="Jenkins"
                />
                <img src="../assets/slack.svg" class="integrated" alt="Slack" />
                <img src="../assets/jira.png" class="integrated" alt="Jira" />
                <img
                  src="../assets/Selenium.svg"
                  class="integrated"
                  alt="Selenium"
                />
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="hiw-sec">
        <div class="w1200 center">
          <h3 class="txt50">{{ home.howHead }}</h3>
          <div class="flex-me top">
            <div
              class="hiw-box"
              v-for="how in home.howIcons"
              :key="how.iconBigText"
            >
              <div class="icon-wrap smaller">
                <img :src="how.icon.imageURL" class="icon" alt="Test" />
              </div>
              <p class="step">{{ how.iconBigText }}</p>
              <p class="">
                {{ how.iconSmallText }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="gray-sec">
        <div class="w1200">
          <div class="flex-me">
            <div class="side-img-wrap">
              <img src="../assets/person-all.svg" class="" alt="Mission" />
            </div>
            <div class="side-txt-wrap">
              <p class="txt28">
                {{ home.mission }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="cta-wrap">
        <h4 class="txt30 semi">Inclusive. Compliant. Simple.</h4>
        <router-link to="/demo" class="btn">REQUEST A DEMO</router-link>
      </div>
    </main>
  </main>
</template>

<script>
import SVGator from "../components/SVGator.vue";
export default {
  components: {
    SVGator,
  },
  computed: {
    home() {
      return this.$state.homepage[0];
    },
  },
  async beforeMount() {
    document.title = "SenseIT | Accessibility Automation";
  },
  methods: {
    formatToHTML(str) {
      return str
        .replace(/\n/, "<br/>")
        .replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, "<b>$1</b>")
        .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, "<i>$1</i>")
        .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, "<s>$1</s>")
        .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, "<u>$1</u>")
        .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, "<tt>$1</tt>");
    },
  },
  data: () => ({
    svgPlay: false,
    opennavbar: false,
  }),
  watch: {
    "$route.path"() {
      if (this.opennavbar) this.opennavbar = false;
    },
  },
  name: "Home",
};
</script>

import demo from "../views/Demo.vue";
import product from "../views/Product.vue";
import pricing from "../views/Pricing.vue";
import blog from "../views/Blog.vue";
import blogpage from "../views/Blogpage.vue";
import about from "../views/About.vue";
import contact from "../views/Contact.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import legal from "../views/Legal.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: about,
  },
  {
    path: "/blog",
    name: "Blog",
    component: blog,
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: pricing,
  },
  {
    path: "/product",
    name: "Product",
    component: product,
  },
  {
    path: "/demo",
    name: "Demo",
    component: demo,
  },
  {
    path: "/contact",
    name: "Contact",
    component: contact,
  },
  {
    path: "/blog/:id",
    name: "blog",
    component: blogpage,
  },
  {
    path: "/pages/:slug",
    name: "pages",
    component: legal,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;

export default class BglEmbed {
    constructor({token}) {
        this.editButton = null;
        this.editPanel = null;
        this.bglFrame = null;
        this.parseJwt(token)
      }
    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c=>('%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))).join(''));
        const t = JSON.parse(jsonPayload);
        this.org = t.organizationID;
        this.baseURL = t.aud;
        this.baseURL = "https://next.bageldb.com"
        this.projectID = t.projectID;
    }
    createEditButton(){
        document.querySelector("#bgl-edit-btn")?.remove()
        this.editButton = document.createElement('button')
        this.editButton.id = "bgl-edit-btn"
        this.editButton.classList.add("bgl-edit-button")
        this.editButton.classList.add("bgl-edit-open")
        this.editButton.onclick = ()=>this.openFrame();
        this.editButton.innerText = "Edit"
        document.body.appendChild(this.editButton);
    }
    createPanel() {
        document.querySelector("#bgl-panel")?.remove()
        this.editPanel = document.createElement('div')
        this.editPanel.id = "bgl-edit-panel"
        this.editPanel.classList.add("bgl-panel")
        this.bglCloseBtn = document.createElement('button')
        this.bglCloseBtn.classList.add('bgl-close')
        this.bglCloseBtn.innerText = '✕'
        this.bglCloseBtn.onclick = ()=>this.closeFrame();
        this.editPanel.appendChild(this.bglCloseBtn);
        const style = document.createElement('style')
        style.innerHTML = `.bgl-editing-doc{transition:padding ease 0.2s}.bgl-pad-doc{margin-right:560px;width: calc(100vw - 560px);}.bgl-edit-button,.bgl-panel{transition:.2s ease-in-out;position:fixed;z-index:9999999;}.bgl-panel{top:0px;border-radius:10px;bottom:0px;height:100vh;right:-580px;width:560px;border:none;box-shadow:0 7px 10px #c8c8c8;background-color:#fff}.bgl-close,.bgl-edit-button{background-color:#2e5bff;color:#fff}.bgl-panel.bgl-panel-open{right:0px}.bgl-frame{border:none;height:100%;width:100%}.bgl-edit-button{border:none;padding:10px 20px;border-radius:10px 0 0 10px;z-index:9999;top:100px;right:-120px;width:auto;font-family:Arial,sans-serif}.bgl-edit-button.bgl-edit-open{right:0}.bgl-close{width:35px;height:35px;line-height:35px;text-align:center;position:absolute;top:10px;left:10px;border-radius:100px;border:none}`
        this.editPanel.appendChild(style);
        document.body.appendChild(this.editPanel);
      }
      createFrame(){
        document.querySelector("#bgl-frame")?.remove()
        document.body.classList.add('bgl-editing-doc')
        this.bglFrame = document.createElement('iframe')
        this.bglFrame.id = "bgl-edit-frane"
        this.bglFrame.classList.add("bgl-frame")
        this.editPanel.appendChild(this.bglFrame);
    }
    init({itemID, collection}){
        this.createEditButton()
        this.createPanel();
        this.createFrame();
        this.itemID = itemID;
        this.collection = collection;
        const {baseURL, org, projectID } = this;
        let url = `${baseURL}/organization/${org}/project/${projectID}/collection/${collection}/item/${itemID}`
        this.bglFrame.src = url;
        window.addEventListener("message", (event) => {
          if (!["https://next.bageldb.com","https://app.bageldb.com"].includes(event.origin))return
          const {data} = event
          if (!Object.keys(data).length)return
            this.onChangeFunction(data)
          }, false);
    }
    onChange(func){ this.onChangeFunction = func; }
    closeFrame(){
        document.body.classList.remove('bgl-pad-doc')
        this.editPanel.classList.remove("bgl-panel-open")
        this.editButton.classList.add('bgl-edit-open')
    }
    openFrame(){
        document.body.classList.add('bgl-pad-doc')
        this.editPanel.classList.add("bgl-panel-open")
        this.editButton.classList.remove('bgl-edit-open')
    }
}

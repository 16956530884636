<template>
  <main id="skip-to-main">
    <div class="top-yallow-bg small"></div>

    <h1 class="big-head">{{pricingPage.title}}</h1>
    <div class="price-boxes-wrap">
      <div class="price-box starter">
        <div class="price-head-wrap">
          <h2 class="price-box-head">Starter</h2>
          <img src="../assets/line-red.svg" class="price-line" />
        </div>
        <div class="rows-wrap">
          <div v-for="checks in pricingPage.starterPoints" :key="checks._id" class="row">
            <img src="../assets/check.svg" class="check-icon" alt="check" />
            <p class="row-txt">{{checks.point}}</p>
          </div>
          <p class="row-end-txt">
{{pricingPage.starterRecommended}}          </p>
        </div>
        <a href="/#contact" class="btn">Contact Us</a>
      </div>
      <div class="price-box">
        <div class="price-head-wrap">
          <h3 class="price-box-head">Continuous</h3>
          <img src="../assets/line-red.svg" class="price-line" />
        </div>
        <div class="rows-wrap">

          <div v-for="checks in pricingPage.continuousPoints" :key="checks._id" class="row">
            <img src="../assets/check.svg" class="check-icon" alt="check" />
            <p class="row-txt">{{checks.point}}</p>
          </div>




          <p class="row-end-txt">{{pricingPage.continuousRecommended}} </p>
        </div>
        <a href="/#contact" class="btn">Contact Us</a>
      </div>
    </div>
    <div class="logos-wrap">
      <a
        v-for="logo in logos"
        :key="logo._id"
        :href="logo.link"
        target="_blank"
        class="logo-partner"
      >
        <img class="partner-logo" :src="logo.logo.imageURL" :alt="logo.name"
      /></a>
    </div>
    <div class="faq-sec">
      <h4 class="big-head space-after-30">{{pricingPage.fAQTitle}}</h4>
      <p class="txt28 semi space-after-50px">
        {{pricingPage.fAQSubtitle}}      </p>
      <img src="../assets/faq.svg" class="faq-image" />
      <div class="faq-wrap">
        <Faq v-for="question in faq" :key="question._id" v-bind="question" />
      </div>
    </div>
    <div class="cta-wrap">
      <h4 class="txt30 semi">Inclusive. Compliant. Simple.</h4>
      <router-link to="/demo" class="btn">REQUEST A DEMO</router-link>
    </div>
  </main>
</template>

<script>
import Faq from "@/components/Faq.vue";
export default {
  components: {
    Faq,
  },
  computed: {
    logos() {
      return this.$state.logos;
    },
    faq() {
      return this.$state.faq;
    },
    pricingPage() {
      if (!this.$state.pricingPage) return {};
      return this.$state.pricingPage[0] || {}
    },
  },
  async beforeMount() {
    document.title = "SenseIT | Pricing";
  },
  name: "Pricing",
};
</script>
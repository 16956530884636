<template>
  <nav class="nav-bar">
    <router-link to="/" title="SenseIT Logo" class="top-logo">
      <img alt="SenseIT Logo" src="../assets/logo.svg" height="" width=""
    /></router-link>
    <div class="nav-empty"></div>
    <div class="nav-links-wrap" :class="{ 'open-nav': opennavbar }">
      <button aria-label="close" class="close-menu" @click="opennavbar = false">
        <img alt="close" src="../assets/close.svg" />
      </button>
      <router-link class="top-link" to="/about"
        >About Us<img
          alt=""
          src="../assets/small-red-line.svg"
          class="nav-line"
      /></router-link>
      <router-link class="top-link" to="/blog"
        >Blog<img alt="" src="../assets/small-red-line.svg" class="nav-line"
      /></router-link>
      <router-link class="top-link" to="/pricing"
        >Pricing<img alt="" src="../assets/small-red-line.svg" class="nav-line"
      /></router-link>
      <router-link class="top-link" to="/product"
        >Product<img alt="" src="../assets/small-red-line.svg" class="nav-line"
      /></router-link>
      <router-link to="/demo" class="btn top-white">Request A Demo</router-link>
    </div>

    <div class="manu-background" @click="opennavbar = false"></div>
    <button aria-label="menu" class="menu" @click="opennavbar = true">
      <img alt="" src="../assets/Menu.svg" />
    </button>
  </nav>
</template>
<script>
export default {
  name: "topnav",
  data: () => ({
    opennavbar: false,
  }),
  watch: {
    "$route.path"() {
      if (this.opennavbar) this.opennavbar = false;
    },
  },
  mounted() {
    let meta = document.createElement("meta");
    meta.name = "description";
    meta.content =
      "Automated usability testing for digital accessibility. Simplify your accessibility testing by leveraging your test automation scripts.";
    // document.head.append(meta);
  },
};
</script>

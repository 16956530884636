<template>
  <main id="skip-to-main">
    <div class="top-yallow-bg small"></div>

    <div class="blog-top" :class="{ loading: !blog._id }">
      <div class="blog-top-txt-wrap">
        <p class="blog-auth">{{ blog.author }}</p>
        <p class="blog-page-date">
          {{ new Date(blog.date).toLocaleDateString() }}
        </p>
        <h1 class="main-blog-head">{{ blog.title }}</h1>
      </div>
      <img
        v-if="blog.image && blog.image.imageURL"
        :src="blog.image.imageURL"
        class="main-blog-img"
        :alt="blog.image.altText"
      />
      <!-- <p v-else style="height: 500px; width: 100%"></p> -->
      <p class="blog-sub-txt" v-html="blog.subtitle"></p>
    </div>
    <div class="blog-main-wrap">
      <div class="share-wrap hide">
        <a href="https://www.facebook.com/SenseIT360" class="share-icon"><img src="../assets/facebook.svg" /></a
        ><a href="" class="share-icon"><img src="../assets/twitter.svg" /></a
        ><a href="https://www.linkedin.com/company/senseitaccess/" class="share-icon"><img src="../assets/linkedin.svg" /></a>
      </div>
      <div
        :class="{ loading: !blog._id }"
        v-html="blog.content"
        class="blog-main-txt"
      ></div>
    </div>
    <div class="other-blogs">
      <p class="txt50 bold center">This may also interest you</p>

      <div class="blogs-wrap">
        <router-link
          v-for="blog in recommended"
          :key="blog._id"
          :to="`/blog/${blog._id}`"
          class="blog-box"
        >
          <div class="blog-box-img-wrap">
            <img :src="blog.image.imageURL" :alt="blog.title" />
          </div>
          <div class="blog-box-txt-wrap">
            <p class="blog-box-top-txt">{{ blog.author }}</p>
            <p class="blog-box-title">{{ blog.title }}</p>
          </div>
          <p class="blog-box-date">
            {{ new Date(blog.date).toLocaleDateString() }}
          </p>
        </router-link>
      </div>
      <div class="under">
        <img src="../assets/line.svg" />
      </div>
    </div>
    <div class="cta-wrap">
      <h4 class="txt30 semi">Inclusive. Compliant. Simple.</h4>
      <router-link to="/demo" class="btn">REQUEST A DEMO</router-link>
    </div>
  </main>
</template>

<script>
import BglEmbed from '../bglEmbed';
export default {
  components: {},
  data(){
    return {
      blog:{},
      recommended:[]
    }
  },
  methods: {
    fetchBlog(){
      const { id } = this.$route.params;
      this.blog = this.$state.blogs.find((b) => b._id === id);
      this.otherBlogs(id)
    },
    otherBlogs(id) {
      const n = 3;
      this.recommended = this.$state.blogs
        .filter((b) => b._id !== id)
        .map((x) => ({ x, r: Math.random() }))
        .sort((a, b) => a.r - b.r)
        .map((a) => a.x)
        .slice(0, n);
    },
  },
  watch:{
    '$route.params'(){
      this.fetchBlog();
    },
  },
  mounted() {
    this.fetchBlog()
    const { id } = this.$route.params;
    document.title = "SenseIT | " + this.blog.title;
      const bglEmbed = new BglEmbed({token:process.env.VUE_APP_BAGEL_TOKEN})
      if (this.$route.query.edit) bglEmbed.init({itemID:id, collection: "blogs"})
      bglEmbed.onChange((blog)=>{
        for (const key of Object.keys(blog)){
          if (blog[key]?.imageURL) console.log(blog[key]?.imageURL);
          if (typeof blog[key] === 'string') this.blog[key] = blog[key]
        }
      })
  },
  name: "Blog",
};
</script>

<template>
  <footer class="footer">
    <a title="SenseIT Home" href="/" class="footer-logo">
      <img src="../assets/logo-white.svg" alt="Sense IT"
    /></a>
    <img alt="" src="../assets/line-footer.svg" />
    <div>
      <router-link to="/about" class="footer-link">About us</router-link>
      <router-link to="/blog" class="footer-link">Blog</router-link
      ><router-link to="/pricing" class="footer-link">Pricing</router-link>
      <router-link to="/product" class="footer-link">Product</router-link>
      <router-link to="/demo" class="footer-link">Request a Demo</router-link>
      <div class="footer-soc-wrp">
        <a
          href="https://www.facebook.com/SenseIT360/"
          target="_blank"
          title="SenseIT Facebook"
          class="footer-link"
        >
          <img alt="facebook" src="../assets/facebook.svg"
        /></a>
        <!-- <a href="" target="_blank" class="footer-link">
          <img alt="twitter" src="../assets/twitter.svg"
        />
        </a> -->
        <a
          title="SenseIT LinkedIn"
          href="https://www.linkedin.com/company/senseitaccess"
          target="_blank"
          class="footer-link"
        >
          <img alt="LinkedIn" src="../assets/linkedin.svg"
        /></a>
      </div>
    </div>
    <div>
      <router-link
        :to="`/pages/${page.slug}`"
        class="page-link"
        v-for="page in pages"
        :key="page._id"
        >{{ page.title }}</router-link
      >
    </div>
  </footer>
</template>
<script>
export default {
  name: "botnav",
  computed: {
    pages() {
      return this.$state.legal;
    },
  },
};
</script>

<style>
.page-link{
  color:rgb(195, 195, 195);
  font-size: 14px;
  padding: 5px;
  text-decoration: none;
}
</style>
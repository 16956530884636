import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
Vue.use(VueRouter);
import Bagel from "@bageldb/bagel-db";

const $db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN);

Vue.prototype.$db = $db;
Vue.config.productionTip = false;

const app = new Vue({
  router,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
});

async function start() {
  const prefetch = [
    "team",
    "faq",
    "logos",
    "blogs",
    "legal",
    "homepage",
    "about",
    "productPage",
    "pricingPage",
  ];

  const data = await Promise.all(
    prefetch.map((col) => $db.collection(col).everything().get())
  );
  const $state = {};
  prefetch.forEach((c, i) => {
    $state[c] = data[i].data;
  });
  Vue.prototype.$state = $state;
  app.$mount("#app");
}

start();

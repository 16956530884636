<template>
  <div :aria-label="question" class="faq-box" :class="{ faqopen: faqOpen }">
    <div @click="faqOpen = !faqOpen" class="faq-icon-wrap">
      <img src="../assets/plus.svg" class="faq-icon plus" />
      <img src="../assets/min.svg" class="faq-icon" />
    </div>
    <button :id="_id" @click="faqOpen = !faqOpen" class="faq-a">
      {{ question }}
    </button>
    <div :aria-labelledby="_id" v-html="answer" class="faq-q"></div>
  </div>
</template>
<script>
export default {
  name: "faq",
  data: () => ({
    tab: 1,
    faqOpen: false,
  }),
  props: {
    _id: { type: String, default: "" },
    question: { type: String, default: "" },
    answer: { type: String, default: "" },
  },
};
</script>






<template>
  <object
    type="image/svg+xml"
    :data="src"
    :lazy="src"
    :alt="alt"
    @load="setComplete"
  ></object>
</template>

<script>
export default {
  props: {
    src: String,
    alt: String,
    paused: Boolean,
    seek: Number,
  },
  data() {
    return {
      player: null,
    };
  },
  watch: {
    seek(position) {
      this.player.seek(position);
    },
    paused(val) {
      if (val) this.player.pause();
      else this.player.play();
    },
  },
  methods: {
    setComplete(e) {
      let d = e.target.contentDocument;
      let el = d.querySelector("svg");
      this.player = el.svgatorPlayer;
      if (this.paused) this.player.pause();
    },
  },
};
</script>

<template>
  <main id="skip-to-main">
    <div class="top-yallow-bg small"></div>
    <div class="main-blog-wrap">
      <router-link
        :to="`/blog/${mainblog._id}`"
        class="main-blog-box"
        :class="{ loading: !mainblog._id }"
      >
        <img
          height="300"
          v-if="(mainblog.image || {}).imageURL"
          :src="mainblog.image.imageURL"
          :alt="mainblog.title"
          class="main-blog-box-img"
        />
        <p v-else style="height: 380px; width: 50%"></p>
        <div class="blog-box-txt-wrap main">
          <p class="blog-box-top-txt main">{{ mainblog.author }}</p>
          <p class="blog-box-title main">
            {{ mainblog.title }}
          </p>
          <p class="blog-box-date main">
            {{ new Date(mainblog.date).toLocaleDateString() }}
          </p>
        </div>
      </router-link>
    </div>
    <div class="blogs-wrap">
      <router-link
        v-for="blog in othernblogs"
        :key="blog._id"
        :to="`/blog/${blog._id}`"
        class="blog-box"
        :class="{ loading: !blog._id }"
      >
        <div class="blog-box-img-wrap">
          <img
            v-if="(blog.image || {}).imageURL"
            :src="(blog.image || {}).imageURL"
            :alt="blog.title"
          />
          <p v-else style="height: 240px; width: 100%; display: block"></p>
        </div>
        <div class="blog-box-txt-wrap">
          <p class="blog-box-top-txt">{{ blog.author }}</p>
          <p class="blog-box-title">{{ blog.title }}</p>
        </div>
        <p class="blog-box-date">
          {{ new Date(blog.date).toLocaleDateString() }}
        </p>
      </router-link>
    </div>
    <div class="under">
      <img src="../assets/sign.svg" />
    </div>
    <div class="cta-wrap">
      <h4 class="txt30 semi">Inclusive. Compliant. Simple.</h4>
      <router-link to="/demo" class="btn">REQUEST A DEMO</router-link>
    </div>
  </main>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    document.title = "SenseIT | Blogs";
  },
  computed: {
    blogs(){
      const blogs = this.$state.blogs || []
      return blogs.sort((a,b)=>new Date(b.date)-new Date(a.date));
    },
    mainblog() {
      if (!this.blogs.length) return {};
      return this.blogs[0];
    },
    othernblogs() {
      if (!this.blogs?.length) return [{}, {}, {}, {}, {}, {}];
      return this.blogs.filter((b) => b._id != this.mainblog._id);
    },
  },
  name: "Blog",
};
</script>

<template>
  <main id="skip-to-main">
    <div class="about-hero">
      <h1 v-html="formatToHTML(about.header)" class="main-head">
        <!-- We are digital

        <span class="head-line-wrap"
          >inclusion<img src="../assets/line-red.svg" class="head-line"
        /></span>
        testers, we are partners, we are change makers. -->
      </h1>
      <img src="../assets/mouse.svg" class="mouse" alt="cursor" />
      <img src="../assets/box-bg.png" class="back-box" alt="box" />
    </div>
    <div v-html="about.aboveTheFold" class="about-wrap">
      <!-- <p>
        The SenseIT team came together around a shared mission to enable an
        inclusive digital world. Members of our team were manual testers and
        accessibility consultants for enterprise companies. We experienced a
        common trend with our clients; accessibility was almost always an
        afterthought. Assessing and fixing web apps for accessibility
        post-production was tedious, time consuming and expensive for our
        clients.
      </p>
      <p>
        SenseIT is revolutionizing digital accessibility assessments by
        introducing an automated platform that performs usability tests directly
        from your user interface.
      </p>
      <p>
        If you are passionate about accessibility or you are interested in
        joining the conversation,
        <a
          title="SenseIT LinkedIn"
          href="mailto:tamar@senseit360.com"
          target="_blank"
          class="small-link"
          >drop us a line.</a
        >
      </p> -->
    </div>
    <div v-html="about.yellow" class="about-yellow-box">
      <!-- <p>
        SenseIT was founded by accessibility professionals and software
        engineers with a mutual goal: to make accessibility accessible for the
        development cycle.
      </p> -->
    </div>
    <div class="gray-sec center">
      <div class="about-wrap"></div>
      <h2 class="txt50">The Team</h2>
      <div class="team-wrap">
        <Teambox v-for="team in members" :key="team._id" v-bind="team" />
      </div>
    </div>
    <div class="about-img">
      <img
        src="../assets/person-scene1.svg"
        class=""
        alt="accessibility relaxing"
      />
    </div>
    <div class="cta-wrap">
      <h3 class="txt30 semi">Inclusive. Compliant. Simple.</h3>
      <router-link to="/demo" class="btn">REQUEST A DEMO</router-link>
    </div>
  </main>
</template>

<script>
import Teambox from "@/components/Teambox.vue";
export default {
  components: {
    Teambox,
  },
    methods: {
    formatToHTML(str) {
      return str
        .replace(/\n/, "<br/>")
        .replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, "<b>$1</b>")
        .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, "<i>$1</i>")
        .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, "<s>$1</s>")
        .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, "<u>$1</u>")
        .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, "<tt>$1</tt>");
    },
  },
  computed: {
    members() {
      return this.$state.team
    },
    about(){
    return this.$state.about[0];
    }
  },
  beforeMount() {
    document.title = "SenseIT | About Us";
  },
  name: "AboutUs",
};
</script>

<template>
  <main id="skip-to-main">
    <div class="top-yallow-bg"></div>

    <div class="top-yellow">
      <div class="pro-top">
        <div class="prod-top-txt-wrap">
          <h1 class="prod-main-head">
            
            <span class="head-line-wrap"
              >{{productPage.title}}<img src="../assets/line-red.svg" class="head-line"
            /></span>
          </h1>
        </div>
        <div class="prod-main-img-wrap">
          <img
            :src="productPage.image.imageURL"
            alt="website"
            class="pro-main-img"
          />
        </div>
      </div>
    </div>
<div class="prod-row-wrap"  v-for="section in productPage.sections" :key="section._id">


    <div class="prod-rows-wrap" v-if="section.sectionType._id === 'cinrp3223akg009t6be0'">
      <div class="prod-row">
        <div class="prod-small-img">
          <img
            :src="section.image.imageURL"
            alt="perspective of your users"
            class="prod-img"
          />
        </div>
        <div class="prod-small-txt-wrap">
          <h2 class="small-prod-head">{{section.title}}</h2>
          <h3>{{ section.subTitle }}</h3>
          <div v-html="section.body">
          </div>
        </div>
      </div>
    </div>
    <div class="gray-sec" v-else>
      <h2 class="small-prod-head center">{{section.title}}</h2>
      <h3 class="w730 center">
        {{section.subTitle}}
      </h3>
    </div>
    </div>
    <div class="cta-wrap gray">
      <h4 class="txt30 semi">
        Every functional test automatically turns into an accessibility test.
      </h4>
      <router-link to="/demo" class="btn">REQUEST A DEMO</router-link>
    </div>
  </main>
</template>

<script>
export default {
  beforeCreate() {
    document.title = "SenseIT | Product";
  },
  data: () => ({}),
  name: "Product",
  computed:{
    productPage() {
      if (!this.$state.productPage) return {};
      return this.$state.productPage[0] || {}
    },
  }
};
</script>

<template>
  <main>
    <div class="top-yallow-bg small"></div>
    <div class="contact-sec" id="contact">
      <div class="form-wrap cont">
        <h1 class="big-head">Contact Us</h1>
        <h5 class="txt30">for more information</h5>
        <form
          @submit.prevent="sendForm"
          v-if="formStatus != 'success'"
          class="form"
        >
          <label for="email" class="label-top">Email Address</label>
          <input
            id="email"
            v-model="form.email"
            type="email"
            class="txt-field"
            placeholder="Email Address"
          />
          <div>
            <div>
              <label for="firstName" class="label-top">First Name</label>
              <input
                id="firstName"
                placeholder="Your First Name"
                v-model="form.firstName"
                type="text"
                class="txt-field"
              />
            </div>
            <div>
              <label for="lastName" class="label-top">Last Name</label>
              <input
                id="lastName"
                placeholder="Your Last Name"
                v-model="form.lastName"
                type="text"
                class="txt-field"
              />
            </div>
          </div>
          <label for="company" class="label-top">Company</label>
          <input
            id="company"
            placeholder="Company Name"
            v-model="form.company"
            type="text"
            class="txt-field"
          />
          <label for="message" class="label-top"> Your Message </label>
          <textarea
            id="message"
            placeholder="Your Message"
            v-model="form.message"
            class="txt-field big"
          ></textarea>

          <div class="form-btn-wrap">
            <input class="btn full" type="submit" value="Submit" />
          </div>
        </form>
        <div class="tnx" v-if="formStatus == 'success'">
          <p class="">Thank You!</p>
        </div>
        <div class="error" v-if="formStatus == 'fail'">
          <p>An error occurred while submitting the forum</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  beforeCreate() {
    document.title = "SenseIT | Contact Us";
  },
  data() {
    return {
      formStatus: "",
      form: {
        email: "",
        firstName: "",
        lastName: "",
        company: "",
        message: "",
      },
    };
  },
  methods: {
    async sendForm() {
      try {
        await this.$db.collection("contact").post(this.form);
        this.formStatus = "success";
      } catch (err) {
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
  },
};
</script>

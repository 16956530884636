<template>
  <div class="blog-main-wrap" style="min-height:55vh">
    <h1>{{ page.title }}</h1>
    <div v-html="page.content" />
  </div>
</template>

<script>
export default {
  beforeCreate() {
    document.title = "SenseIT | Terms";
  },
  computed: {
    page() {
      const { slug } = this.$route.params;
      if (!this.$state.legal) return {};
      let page = this.$state.legal.find((p) => p.slug == slug);
      if (!page) return {};
      return page;
    },
  },
};
</script>

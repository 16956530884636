<template>
  <div :aria-label="name" class="team-box" :class="{ open: bioOpen }">
    <div class="profile-img-wrap">
      <button
        class="profile-btn"
        @click="openBio"
        :style="{ cursor: !!bio ? 'pointer' : 'default' }"
      >
        <img :src="profileImage.imageURL" :alt="name" class="profile-img" />
      </button>
      <a :href="linkedin" class="linkedin" target="_blank">
        <img src="../assets/linkedin.svg" alt="linkedin"
      /></a>
    </div>
    <p class="team-name">{{ name }}</p>
    <p class="team-title">{{ title }}</p>
    <div class="team-bio-wrap">
      <div
        :aria-labelledby="_id"
        tabindex="0"
        class="team-bio"
        v-html="bio"
      ></div>
      <button type="button" class="close-team-bio" @click="openBio">
        <img src="../assets/close.svg" class="close" alt="close" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "teambox",
  data: () => ({
    tab: 1,
    bioOpen: false,
  }),
  methods: {
    openBio() {
      if (!this.bio) return;
      this.bioOpen = !this.bioOpen;
    },
  },
  props: {
    _order:Number,
    _id: { type: String, default: "" },
    bio: { type: String, default: "" },
    linkedin: { type: String, default: "" },
    name: { type: String, default: "" },
    profileImage: {
      type: Object,
      default: undefined,
    },
    title: { type: String, default: "" },
  },
};
</script>

<template>
  <div id="app">
    <topnav />
    <router-view />
    <botnav />
  </div>
</template>

<script>
import Botnav from "./components/Botnav.vue";
import Topnav from "./components/Topnav.vue";
export default {
  name: "App",
  components: { Topnav, Botnav },
};
</script>

<style src="./main.css"></style>
